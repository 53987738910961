import { Component, Input } from '@angular/core';
import { Company, CompanyStatus } from 'src/models/company/company';
import { FunctionalUtils } from 'src/utilities/functionalUtils';

@Component({
  selector: 'app-company-status',
  templateUrl: './company-status.component.html',
  styleUrls: ['./company-status.component.scss']
})
export class CompanyStatusComponent {

  @Input() company: Company

  statusText: string
  color: string
  iconSrc: string
  iconColor: string
  backgroundColor: string
  tooltip: string


  constructor() {
  }

  ngOnChanges() {
    this.statusText = this.company?.status ? FunctionalUtils.toTitleCase(this.company.status.replace("_", " ")) : "Not created"
    switch (this.company?.status) {
      case CompanyStatus.INCOMPLETE:
      case undefined:
        this.iconSrc = "assets/icons/warning_ic.svg"
        this.color = "var(--secondary)"
        this.backgroundColor = 'var(--primary-pale)'
        this.iconColor = 'var(--highlight)';
        break;
      case CompanyStatus.EMAIL_MISSING:
        this.iconSrc = "assets/icons/warning_ic.svg"
        this.color = "var(--secondary)"
        this.backgroundColor = 'var(--primary-pale)'
        this.iconColor = 'var(--highlight)';
        this.statusText = "Unverified"
        break;
      case CompanyStatus.IN_REVIEW:
        this.iconSrc = "assets/icons/history.svg"
        this.color = "var(--deep-sky-blue)"
        this.backgroundColor = 'var(--primary-pale)'
        this.iconColor = 'var(--deep-sky-blue)'
        break;
      case CompanyStatus.UNVERIFIED:
        this.backgroundColor = 'var(--primary-pale)'
        this.color = "var(--tomato)"
        this.iconColor = "var(--tomato)"
        this.iconSrc = "assets/icons/warning_ic.svg"
        break;
      case CompanyStatus.ACTIVE:
        this.backgroundColor = 'var(--primary-pale)'
        this.iconSrc = null
        this.color = "var(--deep-sky-blue)"
        break;
      case CompanyStatus.SF_VERIFIED:
        this.backgroundColor = 'var(--primary-pale)'
        this.iconSrc = 'assets/icons/check-white.svg'
        this.color = "var(--action)"
        this.iconColor = "var(--action)"
        this.statusText = "Verified"
        break;
    }
    this.tooltip = "Your company is "+ this.statusText

  }

}
