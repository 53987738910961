import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgIconModule } from 'src/app/shared/icons/svg-icon/svg-icon.module';
import { CompanyStatusComponent } from './company-status.component';



@NgModule({
  declarations: [
    CompanyStatusComponent
  ],
  imports: [
    CommonModule, 
    SvgIconModule, 
    MatTooltipModule
  ], 
  exports: [CompanyStatusComponent]
})
export class CompanyStatusModule { }
